import React from 'react'

//import components

//import styles
import styles from './Grid.module.css'

const Grid = ({title, text, images, columns, background='white', color='black', shadow=false}) => {

    let applyStyles
    let applyShadow

    switch(columns){
        case 2:
            applyStyles = styles.grid2
            break
        case 3:
            applyStyles = styles.grid3
            break
        case 4: 
            applyStyles = styles.grid4
            break
        default:
            applyStyles = styles.grid3
    }

    if (shadow){
        applyShadow = '5px 5px 6px rgba(0,0,0,.2)'
    }

    return (
        <div className={applyStyles}>
            {images.map((image, index) => 
                <div key={index}>
                    <img
                        src={image.src}
                        alt={image.alt}
                        className={styles.gridImage}
                        style={{boxShadow: applyShadow}}
                    />
                </div>    
            )}
        </div>
    )
}

export default Grid