import React from 'react'
import {Link} from 'gatsby'

//import styles
import mobileNavStyles from './MobileNav.module.css'

//import images
import resumeIcon from '../images/nav-icons/icon-resume.svg'
import phoneIcon from '../images/nav-icons/icon-phone.svg'
import emailIcon from '../images/nav-icons/icon-email.svg'
import homeIcon from '../images/nav-icons/icon-home.svg'

export default () => (
    <div className={mobileNavStyles.mobileNav}>
        <Link to='/'><img src={homeIcon} alt="" /></Link>
        <a href={'/sophie-vakalis.pdf'} target='_blank' rel='noopener noreferrer'>
            <img src={resumeIcon} alt="" />
        </a>
        <a href="tel:+17328825908">
            <img src={phoneIcon} alt="" />
        </a>
        <a href='mailto:s.vakalis15@gmail.com' target='_blank' rel='noopener noreferrer'>
            <img src={emailIcon} alt="" />
        </a>
    </div>
)