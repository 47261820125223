import React from 'react'
import {Link} from 'gatsby'

//import styles
import NextSectionStyles from './NextSections.module.css'

export default ({next, previous}) => (
    <div className={NextSectionStyles.nextSections}>
        <Link to={`/${previous}/`}>{'<'} See Previous Project</Link>
        <Link to={`/${next}/`}>See Next Project {'>'}</Link>
    </div>
)