import React, {useState, useEffect} from 'react'

//import styles
import styles from './InstaSection.module.css'

//import components
import Copy from '../Copy/Copy.component'
import Padding from '../Padding'
import InstaStepper from '../InstaStepper/InstaStepper.component'

//import images
import left1 from '../../images/instagram-graphics/left-1.jpg'
import left2 from '../../images/instagram-graphics/left-2.jpg'
import left3 from '../../images/instagram-graphics/left-3.jpg'

import instaStep1 from '../../images/instagram-graphics/insta-step-1.jpg'
import instaStep2 from '../../images/instagram-graphics/insta-step-2.jpg'
import instaStep3 from '../../images/instagram-graphics/insta-step-3.jpg'

//get window width
function getWindowWidth() {
    if (typeof window !== 'undefined'){
        const { innerWidth: width} = window;
        return width
    }
  }

export default () => {

    const [windowWidth, setWindowWidth] = useState(getWindowWidth())

    useEffect(() => {
        function handleResize() {
          setWindowWidth(getWindowWidth());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    return (
        <>
            <Copy
                title='swipe posts'
                copy={[
                    `Several of the posts were used to highlight aspects of the many services/resources FC360 offers. However, they were not in a usable format for social media.`,
                    `Below are highlights of some of the assets from the website that were adapted into Instagram-friendly swipe posts.`
                ]}
            />
            <Padding size='m' />
            <div className={styles.flexContainer}>

                <div className={styles.left}>
                    <Copy
                        title='original'
                        inContainer={true}
                    />
                    <Padding size='s' />
                    <div className={styles.leftImageContainer}>
                        <img src={left1} alt="screenshot from FC360 website" />
                    </div>
                    <Padding size='m' />
                    <div className={styles.leftImageContainer}>
                        <img src={left2} alt="screenshot from FC360 website" />
                    </div>
                    <Padding size='m' />
                    <div className={styles.leftImageContainer}>
                        <img src={left3} alt="screenshot from FC360 website" />
                    </div>
                </div>

                <div className={styles.right}>
                    <div className={styles.direction}>
                        <Copy
                            title='ig adaptation'
                            inContainer={true}
                        />
                        <p>Click arrow to see swipe</p>
                        <Padding size='s' />
                    </div>
                    
                    <div className={styles.row}>
                        <InstaStepper 
                            image={instaStep1}
                            steps={2}
                            width={windowWidth <= 430 ? 250 : 330}
                        />
                        <Padding size='m' />
                    </div>
                    
                    <div className={styles.row}>
                        <InstaStepper 
                            image={instaStep2}
                            steps={4}
                            width={windowWidth <= 430 ? 250 : 330}
                        />
                        <Padding size='m' />
                    </div>
                    
                    <div className={styles.row}>
                        <InstaStepper 
                            image={instaStep3}
                            steps={3}
                            width={windowWidth <= 430 ? 250 : 330}
                        />
                    </div>
                    
                </div>

            </div>
        </>
    )
}