import React from 'react'

//import styles
import styles from './LinkButtons.module.css'

//import components
import Line from '../Line'

export default ({buttons, title}) => {
    return (
        <div className={styles.linkContainer}>
                <Line color={'black'}/>
                <h3>{title}</h3>
                
                <div className={styles.buttonContainer}>
                    {buttons.map((button, index) => 
                        <a
                            href={button.link}
                            className={styles.linkButton}
                            style={{background: button.color}}
                            target='_blank'
                            rel="noopener noreferrer"
                            key={`${title} ${index}`}
                        >
                            {button.title}
                        </a>
                    )}
                </div>

            </div>
    )
}