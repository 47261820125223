import React from 'react'

export default ({src, alt}) => (
    <img
    src={src}
    alt={alt}
    style={{
        width: '100%',
        lineHeight: 0,
        margin: '0 0 -5px 0'
    }}
    />
)