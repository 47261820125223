import React from 'react'

//import components
import Head from './Head'
import Line from './Line'

//import styles
import styles from './ProjectDescription.module.css'

export default ({title, date, position, employer, brief=[], skills=[]}) => (
    <div className={styles.description}>
        <Head title={title} />
        <h1>{title}</h1>
        <div className={styles.container}>
            <div className={styles.info}>
                <div className={styles.date}>
                    <Line color={'black'}/>
                    <h2>{date}</h2>
                    <p>{position}</p>
                    <p>{employer}</p>
                </div>
                <div className={styles.skills}>
                    <Line className={styles.skillsLine} color={'black'} />
                    <h2 className={styles.skillsHeading}>SKILLS</h2>
                    {skills.map((skill, index) => <p key={index}>{skill}</p>)}
                </div>
            </div>
            <div className={styles.brief}>
                <Line color={'black'}/>
                <h2>BRIEF</h2>
                {brief.map((paragraph, index) => <p key={index}>{paragraph}</p>)}
            </div>
        </div>
    </div>
    
)