import React from 'react'

import styles from './Padding.module.css'

export default ({size = 'l'}) => {
    const getPaddingSize = () => {
        if (size === 's'){
            return styles.paddingSmall
        }
        if (size === 'm'){
            return styles.paddingMedium
        }
        if (size === 'l'){
            return styles.paddingLarge
        }
    }
    return (
        <div className={getPaddingSize()}/>
    )
}