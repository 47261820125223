import React, {useState} from 'react'

//import styles
import styles from './InstaStepper.module.css'

export default ({image, steps, width}) => {

    const [step, setStep] = useState(1)
    const [offset, setOffset] = useState(0)

    const imageContainerDimensions = {
        width: `${width}px`,
        height: `${width}px`
    }

    const moveRight = () => {
        const currentOffset = offset
        const currentStep = step
        if (step < steps){
            setOffset(currentOffset - width)
            setStep(currentStep + 1)
        }
    }

    const moveLeft = () => {
        const currentOffset = offset
        const currentStep = step
        if (step > 1){
            setOffset(currentOffset + width)
            setStep(currentStep - 1)
        }
    }

    return (
        <div className={styles.flexContainer}>
            <div
                className={styles.imageContainer}
                style={imageContainerDimensions}
            >
                <img
                    src={image}
                    alt='instagram post'
                    style={{
                        left: `${offset}px`,
                        height: `${width}px`
                    }}
                />
            </div>
            <div className={styles.buttonContainer}>
                <button
                    onClick={() => moveLeft()}
                    className={step === 1 ? styles.disabled : null}
                >
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" fill="#fff"/>
                            <path d="M0 0h24v24H0z" fill="none"/>
                        </svg>
                    </div>
                </button>
                <button
                    onClick={() => moveRight()}
                    className={step === steps ? styles.disabled : null}
                >
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" fill="#fff"/>
                            <path d="M0 0h24v24H0z" fill="none"/>
                        </svg>
                    </div>
                </button>
                
            </div>
        </div>
    )
}