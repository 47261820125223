import React, {useState, useEffect} from 'react'
import {Link} from 'gatsby'

//import styles
import navStyles from './NavBar.module.css'

const NavBar = ({isHomepage = false}) => {
    

    const [atTop, setAtTop] = useState(true)
    const handleScroll = () => {
        if (window.scrollY > 0){
            setAtTop(false)
        } else {
            setAtTop(true)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
                window.removeEventListener('scroll', handleScroll)
        }
    })

    let initialWidth
    if (typeof window !== 'undefined'){
        initialWidth = window.innerWidth
    }
    const [browserWidth, setWidth] = useState(initialWidth)
    const handleResize = () => {
        setWidth(window.innerWidth)
    }
    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    })  

    return (
        <nav className={
                (atTop && isHomepage)
                    ? navStyles.atTop
                    : navStyles.fixedTop
            }
        >
            <div className={navStyles.content}>
                <div className={navStyles.logo}>
                    <Link to='/'>
                        {browserWidth > 900 ? 'SOPHIE VAKALIS' : 'SOPHIE'}
                    </Link>
                </div>
                <div className={navStyles.contact}>
                    <a
                        href='mailto:s.vakalis15@gmail.com'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        s.vakalis15@gmail.com
                    </a>
                    <a href="tel:+17328825908">732.882.5908</a>
                    <a
                        href={'/sophie-vakalis.pdf'}
                        className={
                            (atTop && isHomepage)
                                ? navStyles.whiteContactButton
                                : navStyles.purpleContactButton
                        }
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        DOWNLOAD RESUME
                    </a>
                </div>
            </div>
        </nav>
    )
}

export default NavBar