import React from 'react'
import {Helmet} from 'react-helmet'

const Head = ({title='Sophie Vakalis'}) => {
    if (title !== 'Sophie Vakalis'){
        title = title + ' | Sophie Vakalis'
    }
    return (
        <Helmet
            title={title}
        />
    )
}

export default Head