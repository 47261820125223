import React from 'react'

//import styles
import styles from './Copy.module.css'

//import components
import Line from '../Line'

export default ({title='', copy=[], inContainer=false}) => {

    const layoutStyles = {
        width: '80%',
        maxWidth: '1000px',
        margin: '0 auto'
    }

    return (
        <div
            className={styles.copy}
            style={inContainer ? null : layoutStyles}
        >
            <Line color={'black'}/>
            <h3>{title}</h3>
            {copy.map((para, index) => <p key={`${title} ${index}`}>{para}</p>)}
        </div>
    )
}