import React from 'react'

//import components
import Project from '../components/Project'
import Padding from '../components/Padding'
import FullWidthImage from '../components/FullWidthImage'
import BackgroundColor from '../components/BackgroundColor/BackgroundColor.component'
import Grid from '../components/Grid'
import NextSections from '../components/NextSections'
import Copy from '../components/Copy/Copy.component'
import LinkButtons from '../components/LinkButtons/LinkButtons.component'
import InstaSection from '../components/InstaSection/InstaSection.component'

// import images
import fullImage1 from '../images/instagram-graphics/full-image-1.jpg'

import gallery1 from '../images/instagram-graphics/gallery1.jpg'
import gallery2 from '../images/instagram-graphics/gallery2.jpg'
import gallery3 from '../images/instagram-graphics/gallery3.jpg'
import gallery4 from '../images/instagram-graphics/gallery4.jpg'
import gallery5 from '../images/instagram-graphics/gallery5.jpg'
import gallery6 from '../images/instagram-graphics/gallery6.jpg'
import gallery7 from '../images/instagram-graphics/gallery7.jpg'
import gallery8 from '../images/instagram-graphics/gallery8.jpg'
import gallery9 from '../images/instagram-graphics/gallery9.jpg'

export default () => (
    <Project
        title={'instagram graphics'}
            date={'2020'}
            position={'Graphic Designer'}
            employer={'Fast Capital 360'}
            brief={[
                'In an effort to rebrand the finance/loan company, Fast Capital 360, a new illustration style and color palette was created and incorporated into their social platforms, specifically Instagram. Due to the more creative and visual-based nature of Instagram, the goal became to create an account that was both informative and visually appealing. I was tasked to create a variety of posts that would not only represent an informative aspect relating to the company and/or current economic climate, but that would also create a positive reaction among followers.',
                'Working with the Illustrator and Art Director of the marketing department, I developed the concepts and designs for over a dozen posts in less than two months, most of which are currently featured on FC360’s official Instagram page. I combined playful illustrations with a data-based approach to develop content that would appeal to our target audience of small business owners and entrepreneurs.'
            ]}
            skills={[
                'Illustration & Layout Design',
                'Data Visualization',
                'Branding',
                'Social Media'
            ]}
    >
        <FullWidthImage src={fullImage1} alt={'instagram posts'} />
        <BackgroundColor color={'#EEEEEE'}>
            <Padding />
            <Grid
                images={[
                        {
                            src: gallery1,
                            alt: 'instagram design'
                        },
                        {
                            src: gallery2,
                            alt: 'instagram design'
                        },
                        {
                            src: gallery3,
                            alt: 'instagram design'
                        },
                        {
                            src: gallery4,
                            alt: 'instagram design'
                        },
                        {
                            src: gallery5,
                            alt: 'instagram design'
                        },
                        {
                            src: gallery6,
                            alt: 'instagram design'
                        },
                        {
                            src: gallery7,
                            alt: 'instagram design'
                        },
                        {
                            src: gallery8,
                            alt: 'instagram design'
                        },
                        {
                            src: gallery9,
                            alt: 'instagram design'
                        }
                ]}
                columns={3}
                shadow={true}
            />
            <Padding />
            <LinkButtons
                title='check out the live page'
                buttons={[
                    {
                        title: 'FC360 IG',
                        link: 'https://www.instagram.com/fastcapital360/',
                        color: '#464FEB'
                    }
                ]}
            />
            <Padding />
        </BackgroundColor>
        <Padding />
        <InstaSection />
        <Padding />
        <Copy 
            title='covid-19'
            copy={[
                'Everyone was affected by the COVID-19 pandemic. During this time, FC360 published many helpful resources on their blog to assist business owners. Therefore, I created several posts to help bring attention to those resources and highlight the importance of remaining positive. Once again, the playful illustration style was incorporated along with a consistent color palette. '
            ]}
        />
        <Padding size='s'/>
        <Grid
            images={[
                    {
                        src: gallery1,
                        alt: 'instagram design'
                    },
                    {
                        src: gallery2,
                        alt: 'instagram design'
                    },
                    {
                        src: gallery3,
                        alt: 'instagram design'
                    }
            ]}
            columns={3}
        />
        <Padding />
        <NextSections
            previous={'social-media-infographics'}
            next={'online-shopping-infographic'}
        />
    </Project>
)