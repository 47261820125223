import React from 'react'

//import components
import NavBar from './NavBar'
import MobileNav from './MobileNav'
import ProjectDescription from './ProjectDescription'
import Footer from './Footer'

export default ({children, title, date, position, employer, brief, skills}) => (
    <div>
        <NavBar />
        <MobileNav />
        <ProjectDescription 
            title={title}
            date={date}
            position={position}
            employer={employer}
            brief={brief}
            skills={skills}
        />
        {children}
        <Footer />
    </div>
)